<template>
  <div class="my-upload-wrap" style="display: inline-block; margin: 0 10px">
    <el-upload
      ref="upload"
      action="https://jsonplaceholder.typicode.com/posts/"
      :on-change="changeFile"
      :auto-upload="false"
      :show-file-list="false"
    >
      <el-button size="small"  :loading="uploading">{{
        text
      }}</el-button>
      <!-- <div slot="tip" class="el-upload__tip">
        只能上传jpg/png/gif/jpeg文件，且不超过5M
      </div> -->
    </el-upload>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "导入",
    },
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      uploading: false,
    };
  },
  components: {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    changeFile(file) {
      let formData = new FormData();
      formData.append("file", file.raw);
      this.loading = true;
      this.$api.commonApi
        .importExcel(this.url, formData)
        .then((res) => {
          this.$message.success("导入成功");
          if (this.$listeners["upload"]) {
            this.$emit("upload");
          }
        })
        .catch((e) => {
          console.log(e);
          this.$message.error("导入失败");
        })
        .finally((e) => {
          this.loading = true;
        });
    },
    setLoading(bool) {
      this.loading = bool;
    },
  },
};
</script>

<style scoped lang="scss">
.my-upload-wrap {
  height: 100%;
}
</style>
