<template>
  <div class="left-menu">
    <el-scrollbar wrap-class="scrollbar-wrapper" style="height: calc(100% - 55px)">
      <!-- 滚动条包裹部分 -->
      <el-menu
        :default-active="curActive"
        class="el-menu-vertical-demo"
        :collapse="isCollapse"
        :router="false"
        unique-opened
      >
        <template v-for="menu in menus">
          <!-- v-if="menu.children && menu.children.length === 1 " -->
          <el-menu-item
            v-if="menu.path === '/' || menu.path === '/event'|| menu.path === '/location_manage' || menu.path==='/material-management' "
            :index="menu.path"
            :key="menu.path"
          >
            <i :class="menu.meta.icon" class="drainage" style="margin-right:6px"></i>
            <span style="display: inline-block;width: 100%" @click="clickMenu(menu)" slot="title">{{ menu.meta.title }}</span>
          </el-menu-item>

          <el-submenu v-else :index="menu.path" :key="menu.path">
            <template slot="title">
              <i :class="menu.meta.icon" class="drainage" style="margin-right:6px"></i>
              <span>{{ menu.meta.title }}</span>
            </template>
            <el-menu-item-group>
              <template slot="title"></template>
              <el-menu-item
                v-for="subMenu in menu.children"
                :key="subMenu.path"
                :index="subMenu.path"
              >
                <!-- <el-popover
                  placement="right"
                  width="100"
                  trigger="hover"
                  v-if="subMenu.path == '/data/video-monitor'"
                >
                  <el-menu router class="el-video-menu">
                    <el-menu-item
                      v-for="item in videoMenus"
                      :key="item.path"
                      :index="item.path"
                      >{{ item.title }}</el-menu-item
                    >
                  </el-menu>

                  <span slot="reference">{{ subMenu.meta.title }}</span>
                </el-popover> -->
                <span style="display: inline-block;width: 100%" @click="clickMenu(subMenu)">{{ subMenu.meta.title }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </template>
      </el-menu>
      <!-- <el-menu-item index="/settings/authority">权限管理</el-menu-item>
            <el-menu-item index="/settings/organ">机构管理</el-menu-item> -->
    </el-scrollbar>
  </div>
</template>

<script>
import local from "@/util/local";
import { mapState } from "vuex";
export default {
  data() {
    return {
      // menus: [],
      videoMenus: [
        {
          path: "/data/video-monitor/video-square",
          title: "视频广场",
        },
        {
          path: "/data/video-monitor/split-screen",
          title: "分屏展示",
        },
        {
          path: "/data/video-monitor/video-alarm",
          title: "视频报警",
        },
        {
          path: "/data/video-monitor/channel-config",
          title: "通道配置",
        },
      ],
      // isCollapse: this.$store.state.menuStatus,
    };
  },
  computed: {
    //菜单保持激活
    curActive() {
      if (this.$route.path === "/home") return "/";
      return this.$route.path;
    },
    // isCollapse() {
    //   return this.$store.state.menuStatus;
    // },
    ...mapState({
      isCollapse: (state) => state.app.menuStatus,
      menus: (state) => state.user.menu,
    }),
  },
  created() {
    // this.menus = local.get("menus");
    // console.log(this.menus, ":sadfasf");
    console.log(this.menus, "safasfa");
  },
  mounted() {
    window.vue = this;
  },
  methods:{
    clickMenu(menu){
      // this.$router.push(path)
      console.log(menu)
      let meta = menu.meta;
      if (meta.link){
        let token = this.$store.state.user.token;
        let currentOrgId = this.$store.state.user.orgId;
        let subPath = meta.subPath;
        console.log(token,"token")
        let toUrl = this.$store.state.app.xjrUrl
        window.open(toUrl+"/login"+"?token="+token+"&orgId="+currentOrgId+"&subPath="+subPath);
      }else {
        this.$router.push(menu.path)
      }
    },
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    }
  }
};
</script>

<style lang="less" scoped>
.left-menu {
  height: 100%;
  padding-top: 30px;
}
.el-menu-vertical-demo {
  border: none;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 259px;
  min-height: 400px;
}
.el-menu-item {
  height: 40px;
  line-height: 40px;
  padding-left: 5%;
}
/deep/.el-submenu__title {
  height: 40px;
  line-height: 40px;
}
/deep/.el-submenu .el-menu-item {
  height: 40px;
  line-height: 40px;
  padding-left: 50px !important;
}
/deep/.el-menu-item-group__title {
  display: none;
}
/deep/.scrollbar-wrapper {
  overflow-x: hidden;
}
/deep/.el-menu-item.is-active {
  border-right: 2px solid var(--themeColor) !important;
}
.el-video-menu {
  border-right: none;
}
</style>
